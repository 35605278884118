import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CalloutIcon from "./CalloutIcon";
import { colors } from "../colors";
import { Typography } from "../Typography";

const CalloutContainer = styled(Container)`
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;
  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }
  h3 {
    color: ${colors.red.getHex()};
  }
  h6 {
    width: 50%;
    color: ${colors.blue.getHex()};
  }

  @media screen and (max-width: 767px) {
    h3 {
      font-size: 37px !important;
    }
    h6 {
      width: 100%;
      font-size: 20px !important;
      font-weight: 300 !important;
    }
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: -2rem;
  @media screen and (max-width: 767px) {
    top: -4rem;
  }
  z-index: -1;
`;

const Divider = styled.div`
  border: 5px solid ${colors.red.getHex()};
  width: 30%;
  margin: 1rem 0 2rem;
  @media screen and (max-width: 767px) {
    width: 50%;
    border: 3px solid ${colors.red.getHex()};
  }
`;

const Callout = ({
  calloutQuote,
  author
}: {
  calloutQuote: {
    calloutQuote: string;
  };
  author?: string;
}) => {
  return (
    <CalloutContainer>
      <ImageWrapper>
        <CalloutIcon />
      </ImageWrapper>
      <Row>
        <Col>
          <Typography.H3>{calloutQuote.calloutQuote}</Typography.H3>
          <Divider />
          {author && <Typography.H6>{`— ${author}`}</Typography.H6>}
        </Col>
      </Row>
    </CalloutContainer>
  );
};

export default Callout;
