import { graphql } from "gatsby";
import * as React from "react";
import { Img } from "wmk-image";
import { WmkSeo } from "wmk-seo";
import Callout from "../components/ui/Callout/Callout";
import CtaBlock from "../components/ui/CtaBlock";
import EventDetails from "../components/ui/EventDetails";
import Hero from "../components/ui/Hero";
import ImageCarouselComp from "../components/ui/ImageCarouselComp";
import SponsorLogos from "../components/ui/SponsorLogos/SponsorLogos";
import { EventQuery } from "../fragments/NodeEventFields";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import { getEventPath } from "../logic/paths";

const EventNode = ({
  data
}: {
  // pageContext: { title: string; eventDetails: { startDate: string } };
  data: { event: EventQuery; site: SiteMetaDataFields };
}) => {
  const image = new Img(data.event.hero.backgroundImage);
  image.addCrop("ogImage", data.event.hero.backgroundImage.ogImage);
  image.addCrop("twitter", data.event.hero.backgroundImage.twitterImage);
  const path = getEventPath(data.event);
  const start = new Date(data.event.eventDetails.startDate);
  const event = data.event;
  return (
    <>
      <WmkSeo.Meta
        title={`${data.event.title} - ${start.toLocaleDateString()}`}
        ogImageUrl={image.url("ogImage")}
        twitterImageUrl={image.url("twitter")}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        description=""
        slug={path.slice(1, path.length)}
      />
      {event.hero && <Hero heroData={event.hero} />}
      {event.eventDetails && (
        <EventDetails eventDetailsData={event.eventDetails} />
      )}
      {event.carousel && <ImageCarouselComp carouselData={event.carousel} />}
      {event.calloutQuote && (
        <Callout
          calloutQuote={event.calloutQuote}
          author={event.calloutAuthor}
        />
      )}
      {event.sponsorLogos && <SponsorLogos sponsorLogos={event.sponsorLogos} />}
      {event.callToAction && (
        <CtaBlock
          headline={event.callToAction.headline}
          subheader={event.callToAction.subhead}
          copy={event.callToAction.copy}
          link={event.callToAction.action}
        />
      )}
    </>
  );
};

export default EventNode;

export const query = graphql`
  query eventQuery($title: String!) {
    event: contentfulEvent(title: { eq: $title }) {
      ...NodeEventFields
    }
    site {
      siteMetadata {
        title
        baseUrl
      }
    }
  }
`;
