import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { Typography } from "./Typography";
import { colors } from "./colors";

const CharityBarContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  .our-goal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .label-text>div: first-child {
    color: ${colors.blue.getHex()};
    text-transform: uppercase;
    top: 5px;
    position: relative;
    font-weight: 600 !important;
    font-size: 13px !important;
  }
  a {
    color: ${colors.red.getHex()};
    margin: 1rem 0;
  }
`;

const BackgroundBar = styled.div`
  height: 2rem;
  width: 100%;
  background: #91f2c8;
  border-radius: 5px;
`;

const CharityBarCol = styled(Col)`
  position: relative;
  margin: 1rem 0;
`;

const FillingBar = styled.div<{ filledPercentage: number }>`
  height: 2rem;
  background: ${colors.green.getHex()};
  border-radius: 5px;
  ${({ filledPercentage }) =>
    filledPercentage ? `width: ${(filledPercentage * 100) <= 100 ? filledPercentage * 100 : 100}%` : ``};
  position: absolute;
  top: 0;
`;

const CharityBar = ({
  currentValue,
  goalValue,
  link,
  style
}: {
  currentValue: number;
  goalValue: number;
  link?: string;
  style?: object
}) => {
  const filledPercentage = currentValue / goalValue;
  return (
    <CharityBarContainer fluid style={{...style}}>
      <Row>
        <Col className="label-text">
          <Typography.Copy>Current</Typography.Copy>
          <Typography.CopyBold style={{ color: colors.blue.getHex() }}>
            ${currentValue.toLocaleString("en-US")}
          </Typography.CopyBold>
        </Col>
        <Col className="our-goal label-text">
          <Typography.Copy>Our Goal</Typography.Copy>
          <Typography.CopyBold style={{ color: colors.blue.getHex() }}>
            ${goalValue.toLocaleString("en-US")}
          </Typography.CopyBold>
        </Col>
      </Row>
      <Row>
        <CharityBarCol>
          <BackgroundBar />
          <FillingBar filledPercentage={filledPercentage} />
        </CharityBarCol>
      </Row>
      {link && (
        <Row>
          <Col>
            <WmkLink to={link} target={"_blank"}>
              <Typography.CopyBold
                style={{ display: "inline", color: colors.red.getHex() }}>
                Donate towards goal →
              </Typography.CopyBold>
            </WmkLink>
          </Col>
        </Row>
      )}
    </CharityBarContainer>
  );
};

export default CharityBar;
