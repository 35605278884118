import React, { useEffect, useRef, useState } from "react";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "./colors";
import FsLightbox from "fslightbox-react";
import { clonedeep } from "lodash";

const SlickDotsWrapper = styled.div<{ imageNum: number; width: number }>`
  margin-bottom: 70px;
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -40px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 60px;
    height: 10px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 60px;
    height: 10px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 10px;

    position: absolute;
    top: 0;
    left: 0;

    width: 60px;
    height: 10px;

    content: "•";
    text-align: center;

    opacity: 0.25;
    color: ${colors.black.getHex()};
    background: ${colors.black.getHex()};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    color: ${colors.red.getHex()};
    background: ${colors.red.getHex()};
    opacity: 1;
  }

  @media screen and (max-width: 767px) {
    // bottom: -140px
    .slick-dots {
      padding: 0 0.5rem;
      text-align: left;
    }
    .slick-dots li {
      width: calc(25% - 14px);
      height: 10px;
      margin: 0 7px;
    }
    .slick-dots li button {
      width: calc(25% - 14px);
      height: 10px;
      margin: 0 7px;
    }
    .slick-dots li button:before {
      width: 100%;
      opacity: 1;
      color: ${colors.teal.getHex()};
      background: ${colors.teal.getHex()};
    }
  }
`;

const CarouselCompContainer = styled(Container)`
  padding: 2rem 0;
  img {
    max-height: 30rem;
  }
`;

const ImageContainer = styled.div`
// img {
//   object-fit: fill !important;
// }
`

const ImageCarouselComp = ({
  carouselData
}: {
  carouselData: ContentfulImageQuery[];
}) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });
  const [viewWidth, setViewWidth] = useState(0);
  const [imageDimentions, setImageDimentions] = useState({
    height: 0,
    width: 0
  });

  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const current = carouselRef.current;
    const rect = current ? current.getBoundingClientRect() : null;
    if (rect) {
      setImageDimentions({ height: rect.height, width: rect.width });
    }
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  const setLightboxHandler = (slide: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: slide
    });
  };

  const isMobileSize = viewWidth < 767;

  const images = carouselData.map((image) => new Img(image));
  const imagesSources = images.map((image) => (image.src ? image.src : ""));

  const mobileImages: Img[] = clonedeep(images).slice(0, 4);
  // const mobileImagesSources: string = clonedeep(imagesSources).slice(0, 4);

  const getCurrentImages = () => {
    if (viewWidth > 767) {
      return images;
    } else {
      return mobileImages;
    }
  };

  const settings = {
    arrows: false,
    slidesToShow: isMobileSize || images.length < 3 ? 1 : 3,
    slidesToScroll: isMobileSize ? 1 : 3,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000
  };

  return (
    <>
      <CarouselCompContainer>
        <Row>
          <SlickDotsWrapper
            ref={carouselRef}
            imageNum={images.length}
            width={imageDimentions.width}>
            {Array.isArray(images) ? (
              <Slider {...settings}>
                {getCurrentImages().map((image, i) => {
                  return (
                    <Col key={image.alt + i}>
                      <ImageContainer
                        style={{ margin: "0 1rem" }}
                        onClick={() => setLightboxHandler(i + 1)}>
                        <WmkImage image={image} style={{objectFit: "scale-down"}}/>
                      </ImageContainer>
                    </Col>
                  );
                })}
              </Slider>
            ) : (
              <></>
            )}
          </SlickDotsWrapper>
        </Row>
      </CarouselCompContainer>
      <>
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={imagesSources}
          slide={lightboxController.slide}
        />
      </>
    </>
  );
};

export default ImageCarouselComp;
