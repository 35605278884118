import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Img, WmkImage } from "wmk-image";
import { EventDetailsQuery } from "../../fragments/NodeEventDetails";
import { getDate, getTime } from "../../logic/dates";
import PmhiButton from "../Buttons/MainButton";
import CharityBar from "./CharityBar";
import { colors } from "./colors";
import { TextLink } from "./TextLink";
import { Typography } from "./Typography";
import { DateTime } from "luxon";

const EventDetailsContainer = styled(Container)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  h2 {
    color: ${colors.red.getHex()};
  }
  .link-col {
    margin-top: 1rem;
  }
  .main-header {
    @media screen and (max-width: 767px) {
      h2 {
        font-size: 30px !important;
      }
    }
  }
  .react-player {
    // height: 375px !important;
    width: 100% !important;
    position: relative;
    @media screen and (max-width: 1000px) {
      height: 180px !important;
      // width: 280px !important;
    }
    @media screen and (max-width: 768px) {
      height: 360px !important;
      width: 80vw !important;
    }
    @media screen and (max-width: 576px) {
      height: 280px !important;
      width: 100% !important;
    }
  }
  .mobile-video-col {
    margin-bottom: 2rem;
  }
  .mobile-image-col {
    margin-bottom: 2rem;
  }
`;

const InnerContainer = styled(Container)`
  border-left: 10px solid ${colors.red.getHex()};
  color: ${colors.secondary.getHex()};
  margin-top: 1rem;
  @media screen and (max-width: 767px) {
    border-left: 5px solid ${colors.red.getHex()};
  }
`;

const EventDetails = ({
  eventDetailsData
}: {
  eventDetailsData: EventDetailsQuery;
}) => {
  const [viewWidth, setViewWidth] = useState(0);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  const data = eventDetailsData;
  const image = new Img(data?.image);
  return (
    <EventDetailsContainer>
      {viewWidth <= 767 && (
        <Row>
          {data?.video?.url ? (
            <Col className="mobile-video-col">
              <ReactPlayer
                className="react-player"
                url={data?.video.url}
                fallback={<WmkImage image={image} />}
              />
            </Col>
          ) : (
            <Col className="mobile-image-col">
              <WmkImage image={image} />
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col className="main-header">
          <Typography.H2>About the event</Typography.H2>
        </Col>
      </Row>
      <Row>
        <Col>
          <InnerContainer>
            <Row>
              <Col md={6} className="details-col">
                <Typography.Copy>
                  <ReactMarkdown>
                    {data?.description?.description}
                  </ReactMarkdown>
                </Typography.Copy>
                {data?.startDate ? (
                  <>
                    <Typography.Copy>{`Date: ${getDate(
                      data?.startDate,
                      data?.endDate
                    )}`}</Typography.Copy>
                    <Typography.Copy>{`Time: ${getTime(
                      data?.startDate,
                      data?.endDate
                    )}`}</Typography.Copy>
                  </>
                ) : (
                  <Typography.Copy>
                    {`Date: ${data.yearOfEvent ? data.yearOfEvent : DateTime.now().year}, To Be Announced`}
                  </Typography.Copy>
                )}
                <Typography.Copy>
                  {`Location: ${data?.location}`}
                </Typography.Copy>
              </Col>
              {viewWidth > 767 && (
                <Col md={6}>
                  {data?.video?.url ? (
                    <ReactPlayer
                      className="react-player"
                      url={data?.video.url}
                      fallback={<WmkImage image={image} />}
                    />
                  ) : (
                    <WmkImage image={image} />
                  )}
                </Col>
              )}
            </Row>
            {data?.registrationUrl && (
              <Row>
                <Col className="link-col">
                  <PmhiButton to={data?.registrationUrl} target={"_blank"}>
                    Register Now
                  </PmhiButton>
                </Col>
              </Row>
            )}
            {data?.eventWebsiteUrl && (
              <Row>
                <Col className="link-col">
                  <TextLink.Arrow to={data?.eventWebsiteUrl} target={"_blank"}>
                    <Typography.CopyBold>
                      Visit event website
                    </Typography.CopyBold>
                  </TextLink.Arrow>
                </Col>
              </Row>
            )}
            {data?.document?.file && (
              <Row>
                <Col className="link-col">
                  <TextLink.Arrow
                    to={data?.document.file.url}
                    target={"_blank"}>
                    <Typography.CopyBold>Team Information</Typography.CopyBold>
                  </TextLink.Arrow>
                </Col>
              </Row>
            )}
            {data?.donationGoal && data?.currentDonations && (
              <CharityBar
                currentValue={data?.currentDonations}
                goalValue={data?.donationGoal}
                link={data?.donationUrl}
                style={{ marginTop: "1rem" }}
              />
            )}
          </InnerContainer>
        </Col>
      </Row>
    </EventDetailsContainer>
  );
};

export default EventDetails;
